import * as React from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { hasValue, parseOptionalIntNanSafe } from '@digital-magic/ts-common-utils'
import QRCode from 'react-qr-code'
import { VehicleId } from '@api/endpoints'
import { getVehiclePrice, WarrantyTypeEnum, WarrantyTypeEnumSchema } from '@api/endpoints/vehicles'
import warrantyBadgeFactory from '@assets/images/badges/waranty_maker.svg'
import warrantyBadgeStandard from '@assets/images/badges/waranty_6.svg'
import warrantyBadgeStandardPlus from '@assets/images/badges/waranty_8.svg'
import warrantyBadgePremium from '@assets/images/badges/waranty_12.svg'
import tick from '@assets/images/badges/tick.svg'
import carprof from '@assets/images/badges/carprof.svg'
import carvertical from '@assets/images/badges/carvertical.svg'
import ifKindlustus from '@assets/images/badges/if.svg'
import teenindus from '@assets/images/badges/teenindus.svg'
import inbankBadge from '@assets/images/badges/in-badge.svg'
import inbank from '@assets/images/badges/inbank.svg'
import googleBadge from '@assets/images/badges/google.svg'
import { useLanguage } from '@hooks/useLanguage'
import { useQueryGetVehicleById } from '@hooks/ReactQuery/vehicles'
import { useTranslateOptionalValue } from '@hooks/Translation/TranslatedValue'
import { useFindFuelType, useFindTransmissionType, useFindWheelDrive } from '@hooks/Translation/TranslatedEntity'
import { formatEuroInEstonian } from '@model/language'
import { CarprofCertified } from '@controls/panels'
import { buildAbsolutePath } from '@pages/Admin/Cars/utils'
import { CarParams } from '../types'
import styles from './CarOffer.module.css'

const warrantyImageMap: Record<WarrantyTypeEnum, string> = {
  [WarrantyTypeEnumSchema.enum.NONE]: warrantyBadgeStandard,
  [WarrantyTypeEnumSchema.enum.FACTORY]: warrantyBadgeFactory,
  [WarrantyTypeEnumSchema.enum.CARPROF_STANDARD]: warrantyBadgeStandard,
  [WarrantyTypeEnumSchema.enum.CARPROF_STANDARD_PLUS]: warrantyBadgeStandardPlus,
  [WarrantyTypeEnumSchema.enum.CARPROF_PREMIUM]: warrantyBadgePremium
}

const warrantyMonthsMap: Record<WarrantyTypeEnum, number> = {
  [WarrantyTypeEnumSchema.enum.NONE]: 0,
  [WarrantyTypeEnumSchema.enum.FACTORY]: 12,
  [WarrantyTypeEnumSchema.enum.CARPROF_STANDARD]: 6,
  [WarrantyTypeEnumSchema.enum.CARPROF_STANDARD_PLUS]: 8,
  [WarrantyTypeEnumSchema.enum.CARPROF_PREMIUM]: 12
}

const warrantyMileageMap: Record<WarrantyTypeEnum, number> = {
  [WarrantyTypeEnumSchema.enum.NONE]: 0,
  [WarrantyTypeEnumSchema.enum.FACTORY]: 0,
  [WarrantyTypeEnumSchema.enum.CARPROF_STANDARD]: 3000,
  [WarrantyTypeEnumSchema.enum.CARPROF_STANDARD_PLUS]: 5000,
  [WarrantyTypeEnumSchema.enum.CARPROF_PREMIUM]: 10000
}

const hasWarranty = (w: WarrantyTypeEnum | undefined): w is WarrantyTypeEnum =>
  hasValue(w) && w !== WarrantyTypeEnumSchema.enum.NONE

export const CarOffer: React.FC = () => {
  const params = useParams<CarParams>()
  const { t } = useTranslation()
  const { routes, language } = useLanguage()
  const translateOptionalValue = useTranslateOptionalValue()

  const carId: VehicleId | undefined = parseOptionalIntNanSafe(params.carId)
  const getCarDetails = useQueryGetVehicleById(carId)

  //const isLoading: boolean = getCar.isFetching || getCarDetails.isFetching

  const transmissionType = translateOptionalValue(
    useFindTransmissionType(getCarDetails.data?.mainData?.transmission?.value)
  )
  const wheeDrive = translateOptionalValue(useFindWheelDrive(getCarDetails.data?.mainData.wheelDrive?.value))
  const fuelType = translateOptionalValue(useFindFuelType(getCarDetails.data?.mainData.fuel?.value))

  const vehicle = getCarDetails.data

  if (!carId || !vehicle) {
    return null
  }

  const vehicleTitle = [vehicle.make, vehicle.model, vehicle.modelExtra].filter(hasValue).join(' ')
  // remove slash at the end

  const vehicleUrl = buildAbsolutePath(
    language,
    generatePath(routes.VehiclePage, {
      vehicleId: carId.toString()
    })
  )
  function formatNumberWithSpaces(mileage: string): string {
    return mileage.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  const warrantyMonths = hasWarranty(vehicle.carWarranty) ? warrantyMonthsMap[vehicle.carWarranty] : undefined
  const warrantyBadge = hasWarranty(vehicle.carWarranty) ? warrantyImageMap[vehicle.carWarranty] : undefined
  const warrantyMileage = hasWarranty(vehicle.carWarranty) ? warrantyMileageMap[vehicle.carWarranty] : undefined

  return (
    <div className={styles.offerPage}>
      <div className={styles.offerPage__gridContainer}>
        <div className={styles.offerPage__carModel__block}>
          <div className={styles.offerPage__carModel__title}>{vehicleTitle}</div>
        </div>
        <div className={styles.offerPage__priceMonth__block}>
          <div className={styles.offerPage__priceMonth__sticker}>
            <div>{t('pages.admin.offer.leasingFrom')}</div>
            <div className={styles.offerPage__priceMonth__amount}>
              {vehicle.monthlyPaymentFrom}
              {t('pages.admin.offer.leasingPrice')}
            </div>
            {t('pages.admin.offer.month')}
          </div>
          <img className={styles.offerPage__priceMonth__badge} src={inbankBadge} alt="inbank" />
        </div>
        <div className={styles.offerPage__carDetails__block}>
          <div className={styles.offerPage__carDetails__item}>
            <div className={styles.offerPage__carDetails__detail}>{t('pages.admin.offer.firstRegistration')}:</div>
            <div className={styles.offerPage__carDetails__value}>{vehicle.mainData.year}</div>
          </div>
          <div className={styles.offerPage__carDetails__item}>
            <div className={styles.offerPage__carDetails__detail}>{t('pages.admin.offer.mileage')}:</div>
            <div className={styles.offerPage__carDetails__value}>
              {formatNumberWithSpaces(String(vehicle.mainData.mileage))} {t('pages.admin.offer.km')}
            </div>
          </div>
          <div className={styles.offerPage__carDetails__item}>
            <div className={styles.offerPage__carDetails__detail}>{t('pages.admin.offer.transmissionType')}:</div>
            <div className={styles.offerPage__carDetails__value}>{transmissionType}</div>
          </div>
          <div className={styles.offerPage__carDetails__item}>
            <div className={styles.offerPage__carDetails__detail}>{t('pages.admin.offer.driveType')}:</div>
            <div className={styles.offerPage__carDetails__value}>{wheeDrive}</div>
          </div>
          <div className={styles.offerPage__carDetails__item}>
            <div className={styles.offerPage__carDetails__detail}>{t('pages.admin.offer.motor')}:</div>
            <div className={styles.offerPage__carDetails__value}>
              {t('pages.admin.offer.motorValue', {
                engineDisplacement: vehicle.mainData.engineDisplacement,
                fuelType: fuelType,
                powerInKw: vehicle.mainData.powerInKw
              })}
            </div>
          </div>
        </div>
        {warrantyBadge && (
          <div className={styles.offerPage__warranty__block}>
            <img className={styles.offerPage__warranty__badge} src={warrantyBadge} alt="warranty" />
          </div>
        )}
        <div className={styles.offerPage__price__group}>
          <div className={styles.offerPage__qr__block}>
            <QRCode size={256} value={vehicleUrl} viewBox={'0 0 256 256'} className={styles.offerPage__qr__image} />
            <div className={styles.offerPage__qr__sign}>{t('pages.admin.offer.look')}</div>
          </div>
          <div className={styles.offerPage__price__block}>
            <div className={styles.offerPage__price__item}>
              <div className={styles.offerPage__price__header}>
                <div>{t('pages.admin.offer.price')}</div>
                <div>{vehicle.isVatIncluded && <span>{t('global.labels.vatIncluded')}</span>}</div>
              </div>
              <div className={styles.offerPage__price__value}>{formatEuroInEstonian(getVehiclePrice(vehicle))}</div>
              <div className={styles.offerPage__price__certificate}>
                <CarprofCertified />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.offerPage__feature__block}>
          <div className={styles.offerPage__feature__item}>
            <img className={styles.offerPage__feature__tick} src={tick} alt="yes" />
            {t('pages.admin.offer.finalPrice')}
          </div>
          <div className={styles.offerPage__feature__item}>
            <img className={styles.offerPage__feature__tick} src={tick} alt="yes" />
            {t('pages.admin.offer.certified')}
          </div>
          {vehicle.carWarranty === 'FACTORY' ? (
            <div className={styles.offerPage__feature__item}>
              <img className={styles.offerPage__feature__tick} src={tick} alt="yes" />
              {t('pages.admin.offer.factoryWarranty', { dueDate: vehicle.warrantyUntil ?? '-' })}
            </div>
          ) : (
            <div className={styles.offerPage__feature__item}>
              <img className={styles.offerPage__feature__tick} src={tick} alt="yes" />
              {t('pages.admin.offer.freeWarranty', {
                warrantMonth: warrantyMonths,
                warrantMileage: warrantyMileage
              })}
            </div>
          )}
        </div>
        <div className={styles.offerPage__badges__block}>
          <img className={styles.offerPage__badges__image} src={carprof} alt="carprof" />
          <img className={styles.offerPage__badges__image} src={googleBadge} alt="google" />
          <img className={styles.offerPage__badges__image} src={teenindus} alt="teenindus" />
        </div>
        <div className={styles.offerPage__partner__block}>
          <div className={styles.offerPage__partner__item}>
            <img className={styles.offerPage__partner__image} src={ifKindlustus} alt="if-kindlustus" />
            {t('pages.admin.offer.insurancePartner')}
          </div>
          <div className={styles.offerPage__partner__itemHorizontal}>
            <img className={styles.offerPage__partner__image} src={carvertical} alt="carvertical" />
            {t('pages.admin.offer.officialPartner')}
          </div>
          <div className={styles.offerPage__partner__item}>
            <img className={styles.offerPage__partner__image} src={inbank} alt="in-bank" />
            {t('pages.admin.offer.postpayPartner')}
          </div>
        </div>
      </div>
    </div>
  )
}
